@mixin media($query)
  @media screen and (nth($query, 1): nth($query, 2))
    @content

@function new-breakpoint($query)
  @return $query

// Breakpoints
$mobile: new-breakpoint(max-width 960px)
$tablet: new-breakpoint(max-width 1200px)
$desktop-small: new-breakpoint(max-width 1600px)
  
.button
  color: var(--white)
  border-radius: 100px
  font-size: 1em
  font-weight: 700
  background-color: var(--blue)
  padding: 13px 74px
  border-width: 2px
  border-style: solid
  border-color: var(--blue)
  font-family: inherit
  transition: all .2s ease-in-out
  &:hover
    border-color: var(--dark-blue)
    background-color: var(--dark-blue)
  &:disabled
    border-color: var(--gray)
    background-color: var(--gray)
    cursor: not-allowed
  &.secondary
    background-color: var(--white)
    color: var(--black)
    border-color: var(--black)
    &:hover
      background-color: var(--black)
      color: var(--white)

.divider
  border-top: 1px solid #DBDBDB

.back-button
  cursor: pointer
  font-weight: 700

.error
  padding: 20px 0
  color: #e1161c
  font-size: 16px

.chaos-page-wrapper
  min-height: 300px
  margin: 0 auto
  max-width: 1100px
  width: 80%
  padding-top: 60px

  .content
    text-align: center
    padding-bottom: 80px
    p
      font-size: var(--fs-body)
      color: var(--dark-gray)
  a
    color: var(--blue) !important
    text-decoration: none
    cursor: pointer

.free-trials
  .content
    &.tool, &.role-form
      padding-bottom: 80px
    &.impact
      display: flex
      flex-direction: column
      gap: 30px
      font-size: 13px
      color: #525252
      text-align: left
      padding: 0 30px
      .perks
        padding-left: 20px
        display: flex
        flex-direction: column
        gap: 15px
        img
          vertical-align: text-top
          margin-right: 10px
      .actions
        text-align: center
        .reject
          color: var(--blue)
          font-weight: bold
          cursor: pointer
  .step
    padding-top: 50px

    hr
      margin-top: 20px
      margin-bottom: -20px
      width: 400px
    a
      color: var(--blue) !important
  .verify-confirm
    .subtitle
      margin: -95px auto 50px !important
    .middot
      color: #ABABAB
  .verify
    padding-top: 0
  .phone-verification
    display: flex
    justify-content: center
    align-items: end
    .item
      &.item-code
        margin-right: 25px
        min-width: 100px
      &.item-country
        width: 100%
  .spacer
    margin-bottom: 50px

  .subtitle
    font-size: 17px
    margin: 5px auto 50px auto
    max-width: 450px
  .button
    width: 100%
    height: 46px

  .roles
    display: flex
    margin: 0 auto
    width: 880px
    div
      border: 1px solid var(--light-gray)
      flex-grow: 1
      justify-content: space-between
      margin: 10px
      padding: 20px 10px
      border-radius: 5px
      cursor: pointer
      width: 217px
      &:hover, &.selected
        border: 1px solid var(--blue)
        background-color: var(--lightest-blue)
    span
      font-size: 13px
      text-transform: capitalize
      display: inline-block
      margin-top: 5px
    img
      height: 24px

  .tools
    display: flex
    flex-wrap: wrap
    justify-content: center
    margin: 0 auto
    max-width: 700px
    div
      border: 1px solid var(--light-gray)
      margin: 10px
      padding: 10px
      border-radius: 5px
      cursor: pointer
      width: 140px
      img
        padding: 4px
      &:hover, &.selected
        border: 1px solid var(--blue)
        background-color: var(--lightest-blue)
    span
      font-size: 13px
      text-transform: capitalize
      display: inline-block
  .terms
    font-size: 11px !important
  .download
    max-width: 445px
    margin: auto
    img
      margin-bottom: 30px
    p
      color: var(--black)
      font-size: 17px
      margin-bottom: 30px
  .enscape-impact
    margin-bottom: 40px
    .info
      font-size: 11px
      color: #828282
      img
        vertical-align: middle
    .buttons
      display: flex
      margin-top: 15px
      gap: 15px
      justify-content: center
      button
        padding: 10px 27px
        width: auto
        border-radius: 4px
        border: 1px solid #EDEDED
        background: white
        color: #1C1C1C
        font-weight: normal
        &.selected
          border-color: #0DB5FF
        &:hover
          border-color: #0DB5FF
          color: #0DB5FF
    .tooltip
      position: relative
      display: inline-block
    .tooltiptext
      visibility: hidden
      width: 330px
      color: #1C1C1C
      background: white
      text-align: left
      border-radius: 4px
      padding: 20px
      /* Position the tooltip */
      position: absolute
      z-index: 1
      top: -185px
      left: -1px
      box-shadow: 0px 4px 15px 0px #1C1C1C33
      @include media($desktop-small)
        left: -165px
      @include media($tablet)
        left: -320px
      .tooltiptitle
        font-size: 13px
        font-weight: 700
      .text
        margin-top: 15px
        margin-bottom: 10px
      a
        color: #0DB5FF !important

    img:hover ~ .tooltiptext
      visibility: visible
    .tooltiptext:hover
      visibility: visible

.form-field-wrapper
  font-size: 13px
  text-align: left
  &.phone
    display: flex
    .styled-select
      max-width: 140px
      margin-right: 15px
    .item
      width: 100%
    input
      -moz-appearance: textfield
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button
        -webkit-appearance: none
        margin: 0
  &.box-buttons
    display: flex
    justify-content: space-between
    .box-button
      width: 140px
      background-color: var(--lighter-gray)
      border: 1px solid var(--lighter-gray)
      border-radius: 4px
      cursor: pointer
      padding: 15px 0
      text-align: center
      &.selected
        border-color: var(--blue)
        background-color: #EBF8FF
      &:hover
        border-color: var(--blue)
  label
    display: block
    margin-bottom: 4px
  input, .styled-select
    border-radius: 4px
    border: 1px solid var(--lighter-gray)
    background-color: var(--lighter-gray)
    padding: 17px
    height: 46px
    width: 100%
    font-size: 13px
    font-family: inherit
    outline: none
    &:hover, &:focus
      border-color: var(--blue)
  .styled-select
    overflow: hidden
    padding: 0
    position: relative
    select
      position: relative
      z-index: 2
    &:after
      content: ""
      position: absolute
      right: 15px
      top: 20px
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8cGF0aCBkPSJNMSAxLjVMNiA2LjVMMTEgMS41IiBzdHJva2U9IiM1MjUyNTIiIHN0cm9rZS13aWR0aD0iMS41IiAvPgo8L3N2Zz4K')
      background-repeat: no-repeat
      width: 12px
      height: 10px
      z-index: 1

    &:hover
      border-color: var(--blue)
  select
    background-color: transparent
    border: none
    width: 120%
    font-size: 13px
    font-family: inherit
    outline: none
    padding: 1em
    option
      &::after
        border: 1px solid red
        display: block
        width: 10px
        height: 10px
        content: '1224'

.role-form
  p
    font-size: 13px !important
    margin-bottom: 4px
    color: var(--dark-gray)
  max-width: 500px
  margin: 0 auto
  text-align: left !important
  .centered
    text-align: center !important
  a
    color: var(--blue) !important

.welcome-back
  max-width: 450px
  margin: 0 auto
  margin-top: -75px
  .subtitle
    font-size: 14.5px
    margin: 30px 0
  .actions
    display: flex
    flex-direction: column
    max-width: 365px
    margin: 0 auto
    gap: 15px
    .button
      height: 46px
      align-items: center
      display: inline-flex
      justify-content: center
  .divider
    margin-top: 60px
    margin-bottom: 30px
  .need-help
    font-size: 13px
  a
    color: var(--blue) !important
  

  span
    display: block

.occupation
  p
    text-align: center
    font-size: 13px
    color: var(--dark-gray)
  .box-buttons
    justify-content: center
    gap: 20px
    .box-button
      display: flex
      flex-direction: column
      align-items: center
      font-size: 13px
      width: 215px
      background-color: var(--white)
      img
        height: 30px
        margin-bottom: 5px


.optional-label
  color: #ababab

.loading
  text-align: center

.logo
  top: 0 !important
  left: 40% !important
  img
    height: 90px !important

.top-wrapper
  margin-bottom: 0 !important

.wrapper-shrink
  .loading
    margin-top: 200px

.column.content
  @include media($mobile)
  padding: 0 !important
  .top-wrapper
    @include media($mobile)
    padding-left: 0 !important
    .mobile-logo
      @include media($mobile)
      margin: 0 !important

.trial-banner-content
  text-align: center
  color: white
  width: 420px
  margin: 357px auto auto
  img
    margin-bottom: 20px
  .quote
    margin-bottom: 16px
    font-size: 17px
  .author
    .name
      font-size: 23px
    .occupation
      font-size: 13px
.image-cover-logo
  text-align: center
  margin-top: 100px
.trial-products-strip
  display: flex
  flex-direction: column
  width: 40px
  position: absolute
  right: 15px
  bottom: 80px
  gap: 6px

.image-cover-logo
  text-align: center
  margin-top: 100px
