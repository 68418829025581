.maya-ple
  margin-top: -60px
  .section-title-wrapper
    padding: 35px 0
    background: #1c1c1c
    position: absolute
    left: 0
    width: 100%
    .section-title
      text-align: center
      color: white
  .occupation.content
      padding-bottom: 0
  .content.free-trials
    padding-top: 150px
  &.envision
    .content
      width: 600px
      margin: 0 auto