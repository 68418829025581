@mixin media($query)
  @media screen and (nth($query, 1): nth($query, 2))
    @content

@function new-breakpoint($query)
  @return $query

@mixin top-padding($count: 1)
  padding-top: 10px * $count

@mixin bottom-padding($count: 1)
  padding-bottom: 10px * $count

@mixin top-offset($count: 1)
  margin-top: 10px * $count

@mixin bottom-offset($count: 1)
  margin-bottom: 10px * $count

// Breakpoints
$mobile: new-breakpoint(max-width 960px)
$tablet: new-breakpoint(max-width 1200px)
$small-laptop: new-breakpoint(max-width 1300px)
$standard-desktop: new-breakpoint(max-width 1440px)

.corona-demo
  display: flex
  flex-direction: row
  width: 100%
  height: 100%
  position: fixed

  .column
    &.image-cover
      background-size: cover
      background-position: 50%
      width: 40%
      @include media($mobile)
        display: none

      &:after
        content: attr(data-copyright)
        color: var(--white)
        font-size: 10px
        position: absolute
        bottom: 10px
        right: 61%

      .logo
        position: absolute
        font-size: 0px
        top: 22px
        left: 41%
        img
          height: 50px

    &.content
      width: 60%
      overflow: auto
      text-align: center
      padding-top: 80px !important
      @include media($mobile)
        width: 100%

      &:after
        content: ''
        display: 'block'
        position: relative
        clear: both

      .top-wrapper
        height: 100px
        @include bottom-offset(4)
        @include media($mobile)
          display: flex
          flex-direction: row
          justify-content: space-between
          padding-left: 10px
          padding-right: 50px

        .mobile-logo
          @include top-offset(3)
          display: none
          float: left
          @include media($mobile)
            display: block
            margin-top: 10px

          img
            @include media($mobile)
              max-width: 200px
      .success
        h3, h4
          font-weight: 400
      .error
        &.small
          font-size: 14px
        &.minor
          color: var(--black)
        h4
          line-height: 2.5rem

      a
        color: var(--brand)
        text-decoration: none
        &.blue-anchor
          color: var(--blue)

      .wrapper-shrink
        width: 500px
        margin-right: auto
        margin-left: auto
        @include media($mobile)
          width: 80%

        .title
          text-align: center
          font-size: 31px
          color: var(--black)
          @include media($tablet)
            font-size: 28px




